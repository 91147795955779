import Footer from '../../../components/Footer';
import Navigation from '../../../components/Nav/navbar';
import { useI18n } from '../../../hooks/useI18n';

export default function SuntemJba() {
  const { language, LANGUAGES } = useI18n();

  return (
    <div>
      <Navigation />
      <div className="herogrupul" />
      <div className="inside-page">
        {
          language === LANGUAGES.RO ?
            <>
              <p>
                JBA a fost fondată în 1995, ca societate cu răspundere limitată, „Jeremy Benn
                Associated Limited” în Anglia, Marea Britanie. În noiembrie 2011 JBA a fost
                reorganizată sub denumirea de JBA Group.
              </p>
              <p>
                Astazi în cadrul companiei activează aproximativ 900 angajați și inregistreaza o cifră
                de afaceri anuală de aproximativ 50 mil. euro.
              </p>
              <p>
                Managementul riscului la inundații reprezintă activitatea definitorie a portofoliului
                JBA. JBA oferă servicii complexe care includ modelarea și evaluarea hazardului la
                inundații, cartografierea riscului la inundații, prognozarea și monitorizarea
                inundațiilor, modelarea și planificarea măsurilor de reducere a riscului la inundații,
                evaluarea schimbărilor climatice.
              </p>
              <p>
                JBA a dezvoltat sute de proiecte în America de Nord, Europa, Asia și Australia.
                Specialiștii din întreg JBA Group dețin o expertiză complexă și competitivă, urmărind
                integrarea noilor tehnologii și bunelor practici într-o serie de sectoare tradiționale de
                gestionare a apei. Proiectele JBA reprezintă un sprijin în reducerea expunerii
                riscurilor naturale, în creșterea rezilienței comunităților și reducerea costurilor pentru
                gestionarea hazardelor naturale. JBA realizeaza în medie peste 1.000 de proiecte pe
                an, acoperirea georgafică a acestora, variind de la scară locală până la scără
                continentală.
              </p>
              <p>
                Grupul JBA este acreditat la standardele ISO 9001 (Asigurarea Calității), ISO 14001
                (Managementul Mediului) și Standardele OHAS 18001 (Sănătate și Securitate).
              </p>
            </>
            : <>
              <p>
                JBA was founded in 1995, as Jeremy Benn Associated Limited in England, Great
                Britain. In November 2011 JBA restructured to form a new group of companies, the
                JBA Group.
              </p>
              <p>
                Today company has approximately 900 employees with an annual turnover of
                approximately 50 million euros.
              </p>
              <p>
                Flood risk management is the largest area of JBA business. JBA provides a
                comprehensive service including modelling and flood hazard assessment, flood risk
                mapping, forecasting and monitoring of floods, modelling and planning of mitigation
                measures, climate change assessment.
              </p>
              <p>
                JBA has carried out many hundreds of flood mapping projects across North
                America, Europe, Asia and Australia. Experts from throughout the JBA Group own a
                complex and competitive expertise, aiming at integrating new technologies and
                good practices in a series of traditional water management sectors. JBA can support
                you to reduce exposure to the natural hazards impacts, increase resilience of local
                communities and reduce costs for natural hazards management. JBA undertakes, in
                average, over 1,000 natural hazard management projects a year ranging in
                geographic scale from whole continents to single sites.
              </p>
              <p>
                JBA has an independent status as an employee-owned company.  This has been engrained in our company philosophy since the company was formed in 1995.
              </p>
              <p>
                The JBA Group is accredited to the ISO 9001 (Quality Assurance), ISO 14001
                (Environmental Management) and OHAS 18001 (Health and Safety) standards.
              </p>
            </>
        }
      </div>
      <Footer />
    </div>
  );
}
